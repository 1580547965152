import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.css";
import { Root } from "./Root";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

import { initBackend } from "@discoverr/web/backend";

initBackend(
  axios.create({
    baseURL: process.env["REACT_APP_API"] as string,
    timeout: 20000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
);

LicenseInfo.setLicenseKey(
  "e68cd566fc16f7d88fb63c29f4845fbeTz05Mzg0OSxFPTE3NTIwNjAyNTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Root />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  // <React.StrictMode>
    <Root />
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
